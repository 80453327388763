import { render, staticRenderFns } from "./NotificacaoCriar.vue?vue&type=template&id=9bdd6504&"
import script from "./NotificacaoCriar.vue?vue&type=script&lang=js&"
export * from "./NotificacaoCriar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports